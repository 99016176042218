// This is the template for all the signs
import * as React from "react";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { TimelineIcon } from "../../images/icons";

// ------------> SignsGrid  <------------ //
const SignsGridClean = ({ signFilteredData }) => {
  // ------> Fade Signs Items when apearing <------ //
  const [fadeItem, setFadeItem] = React.useState("30");
  // ------> Effect for Fade Signs <------ //
  React.useEffect(() => {
    setFadeItem(fadeItem === "30" && "100");
  }, [fadeItem]);

  // ------> Pin Scroll Position <------ //
  // Helper function that allows finding first element in the view port
  const findFirstElementInViewPort = (elements) =>
    Array.prototype.find.call(
      elements,
      (element) => element.getBoundingClientRect().y >= 85 // nav height offset
    );
  // Ref to the container with elements
  const containerRef = React.useRef(null);
  const scrollTo = React.useMemo(() => {
    // Find all elements in container which will be checked if are in view or not
    const nodeElements = containerRef.current?.querySelectorAll("[data-item]");
    if (nodeElements) {
      return findFirstElementInViewPort(nodeElements);
    }
    return undefined;
    // }, [signFilteredData]);
  }, []);

  React.useLayoutEffect(() => {
    if (scrollTo) {
      // Scroll to element with should be in view after rendering
      scrollTo.scrollIntoView();
      // Scroll by height of nav
      window.scrollBy(0, -85);
    }
  }, [scrollTo, signFilteredData]);

  return (
    <div className="relative">
      <div
        ref={containerRef}
        className=" text-center place-items-center grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-[2.6em] mt-[2.6em]"
      >
        {/* <div className="grid place-items-center"> */}
        {signFilteredData.map((sign, index) => {
          // --->  Gatsby Image Const <--- //
          const image = getImage(
            sign.heroImage.localFile.childImageSharp.gatsbyImageData
          );

          return (
            <div key={index} data-item="true">
              <Link
                to={`/signs/${sign.slug}`}
                className={`cursor-pointer opacity-${fadeItem} transition ease-in-out duration-300`}
              >
                <div className="group transition ease-in-out p-0 ">
                  <div className="mb-[0.65em] grid-container ">
                    <div className="item-grid-content">
                      {sign.heroImage && (
                        <GatsbyImage
                          image={image}
                          alt={sign.heroImage.caption || sign.slug}
                          className="opacity-100 group-hover:opacity-10 duration-300"
                          imgStyle=""
                        />
                      )}
                    </div>
                    {/* Caption on hover */}
                    <div className="item-grid-overlay flex flex-col justify-center opacity-0 scale-95 group-hover:scale-100 group-hover:opacity-100 duration-300 text-white w-full h-full z-10">
                      <div className="capitalize">{sign.title}</div>
                    </div>
                  </div>
                  <div className="mt-[1.3em] opacity-100 group-hover:opacity-0 duration-300 ">
                    <div className="w-full flex justify-center translate-y-0 group-hover:translate-y-5 duration-300">
                      <TimelineIcon w={"25"} />
                    </div>
                    <div className="text-white text-base  mt-[1.3em]">
                      {sign.year}
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SignsGridClean;
