import * as React from "react";
import DropDownList from "./dropDownList";
import { ArrowDownPlain } from "../../images/icons";

const DropDownButton = ({
  menuItems,
  signs,
  setSignFilteredData,
  setSelectedSignCategory,
  setSelectedSignLocation,
  selectedSignCategory,
  selectedSignLocation,
  menuTitle,
  selected,
  setSelected,
}) => {
  const [dropdown, setDropdown] = React.useState(false);
  let ref = React.useRef();

  React.useEffect(() => {
    const handler = (event) => {
      if (dropdown && ref.current && !ref.current.contains(event.target)) {
        setDropdown(false);
      }
    };
    document.addEventListener("mousedown", handler);
    document.addEventListener("touchstart", handler);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("touchstart", handler);
    };
  }, [dropdown]);

  // Hover only on big screens
  const onMouseEnter = () => {
    window.innerWidth > 960 && setDropdown(true);
  };
  const onMouseLeave = () => {
    window.innerWidth > 960 && setDropdown(false);
  };
  //  close
  const closeDropdown = () => {
    dropdown && setDropdown(false);
    // setTopSelected(menuTitle);
  };
  function handleKeyDown(e) {
    if (e.keyCode === 13) {
      closeDropdown();
    }
  }

  return (
    <div
      className="mr-5 outline-none"
      ref={ref}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      role="button"
      tabIndex={0}
      onKeyDown={handleKeyDown}
      onClick={() => {
        closeDropdown();
        // setTopSelected(menuTitle);
      }}
    >
      <div className="w-full">
        <button
          className="capitalize flex items-center mb-2 hover:text-amber-300 duration-100"
          type="button"
          aria-haspopup="menu"
          aria-expanded={dropdown ? "true" : "false"}
          onClick={() => {
            setDropdown((prev) => !prev);
            // setTopSelected(menuTitle);
          }}
        >
          <ArrowDownPlain w={`${10}`} classes={"mr-4"} />
          <div className="pl-1">{menuTitle}</div>
        </button>
      </div>
      <DropDownList
        menuTitle={menuTitle}
        dropdown={dropdown}
        menuItems={menuItems}
        signs={signs}
        setSignFilteredData={setSignFilteredData}
        setSelectedSignCategory={setSelectedSignCategory}
        setSelectedSignLocation={setSelectedSignLocation}
        selectedSignCategory={selectedSignCategory}
        selectedSignLocation={selectedSignLocation}
        selected={selected}
        setSelected={setSelected}
      />
    </div>
  );
};

export default DropDownButton;
