export const GetCategories = (categories) => {
  let categoriesItems = categories.map((singleSign) =>
    singleSign.categories.map((singleSignCategories) => {
      return singleSignCategories.category;
    })
  );
  let uniqueCategories = new Set(categoriesItems);
  let categoriesAll = Array.from(uniqueCategories);
  categoriesAll = ["All", ...new Set(categoriesAll.flat())];

  return categoriesAll;
};

export const HandleCategories = (
  category,
  signs,
  setSignFilteredData,
  setSelectedSignCategory,
  setTopSelected
) => {
  if (category === "All") {
    setSignFilteredData([...signs]);
    setSelectedSignCategory(category);
    setTopSelected({ type: "category", item: "All" });
  } else {
    setSignFilteredData([
      ...signs.filter((edge) => {
        return (
          edge.categories.map((i) => i.category).includes(category) && category
        );
      }),
    ]);
    setSelectedSignCategory(category);
    setTopSelected({ type: "category", item: category });
  }
};
