export const GetLocations = (locations) => {
  let locationsItems = locations.map((singleSign) =>
    singleSign.locationContext.map((singleSignLocations) => {
      return singleSignLocations.country;
    })
  );

  let uniqueLocations = new Set(locationsItems);
  let locationsAll = Array.from(uniqueLocations);
  locationsAll = ["All", ...new Set(locationsAll.flat())];

  return locationsAll;
};

export const HandleLocations = (
  location,
  signs,
  setSignFilteredData,
  setSelectedSignLocation,
  setTopSelected
) => {
  if (location === "All") {
    setSignFilteredData([...signs]);
    setSelectedSignLocation(location);
    setTopSelected({ type: "locations", item: "All" });
  } else {
    setSignFilteredData([
      ...signs.filter((edge) => {
        return (
          edge.locationContext.map((i) => i.country).includes(location) &&
          location
        );
      }),
    ]);
    setSelectedSignLocation(location);
    setTopSelected({ type: "locations", item: location });
  }
};
