import * as React from "react";
import { HandleCategories } from "../../utils/categoriesFilter";
import { HandleLocations } from "../../utils/locationsFilter";

const DropDownList = ({
  dropdown,
  menuItems,
  signs,
  setSignFilteredData,
  setSelectedSignCategory,
  setSelectedSignLocation,
  selectedSignCategory,
  selectedSignLocation,
  menuTitle,
  selected,
  setSelected,
}) => {
  const selectedRef = React.useRef(null);

  // ---> Test: <--- //
  // React.useEffect(() => {
  //   console.log(topSelected);
  //   return () => {
  //   };
  // }, [selectedSignCategory]);

  return (
    <div
      className={`absolute z-30 w-fit ml-[26px] ${
        dropdown ? "block" : "hidden"
      }`}
    >
      {menuItems.map((menuItem, index) => {
        return (
          <button
            ref={selectedRef}
            className={`block capitalize hover:text-amber-300 
            relative w-fit leading-[1.6em] 
            
            ${
              selected &&
              selected.type === "category" &&
              selected.item === menuItem
                ? "text-amber-300"
                : selected &&
                  selected.type === "locations" &&
                  selected.item === menuItem
                ? "text-amber-300"
                : "text-white"
            } `}
            key={index}
            onClick={() =>
              menuTitle === "category"
                ? HandleCategories(
                    // Selected Category from list of unique //
                    menuItem,
                    // The full data of signs //
                    signs,
                    // States
                    setSignFilteredData,
                    setSelectedSignCategory,
                    setSelected,
                    selectedSignCategory
                  )
                : menuTitle === "locations" &&
                  HandleLocations(
                    // Selected Category from list of unique //
                    menuItem,
                    // The full data of signs //
                    signs,
                    // States
                    setSignFilteredData,
                    setSelectedSignLocation,
                    setSelected,
                    selectedSignLocation
                  )
            }
          >
            <span className="inline px-1 bg-black bg-opacity-50 box-decoration-clone duration-100">
              {menuItem}
            </span>
          </button>
        );
      })}
    </div>
  );
};

export default DropDownList;
