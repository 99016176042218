import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../../components/layout";
import DropDownButton from "../../components/dropDownMenu/dropDownButton";
import { GetCategories } from "../../utils/categoriesFilter";
import { GetLocations } from "../../utils/locationsFilter";
import SignsGridClean from "../../components/signGrid/signsGridClean";
import { Seo } from "../../components/seo";

// ------------> Signs (plural) page <------------ //
const Page = (props) => {
  // ---> Get all Signs <--- //
  const signs = props.data.allStrapiSign.nodes;
  // const events = props.data.allStrapiEvent.nodes;
  // ------> Filtered data <------ //
  const [signFilteredData, setSignFilteredData] = React.useState(signs);
  // ------> Unique category selected <------ //
  const [selectedSignCategory, setSelectedSignCategory] = React.useState(
    GetCategories(signs) && GetCategories(signs)[0]
  );
  // ------> Unique location selected <------ //
  const [selectedSignLocation, setSelectedSignLocation] = React.useState(
    GetLocations(signs) && GetLocations(signs)[0]
  );
  // Selected Menu top
  const [selected, setSelected] = React.useState(null);

  // ------> Pin Scroll Position <------ //
  // Helper function that allows finding first element in the view port
  const findFirstElementInViewPort = (elements) =>
    Array.prototype.find.call(
      elements,
      (element) => element.getBoundingClientRect().y >= 85 // nav height offset
    );
  // Ref to the container with elements
  const containerRef = React.useRef(null);
  const scrollTo = React.useMemo(() => {
    // Find all elements in container which will be checked if are in view or not
    const nodeElements = containerRef.current?.querySelectorAll("[data-item]");
    if (nodeElements) {
      return findFirstElementInViewPort(nodeElements);
    }
    return undefined;
  }, []);
  // }, [signFilteredData]);

  React.useLayoutEffect(() => {
    if (scrollTo) {
      // Scroll to element with should be in view after rendering
      scrollTo.scrollIntoView();
      // Scroll by height of nav
      window.scrollBy(0, -85);
    }
  }, [scrollTo, signFilteredData]);

  return (
    <Layout
      location={props.location}
      title={props.data.site.siteMetadata.title}
    >
      <div className=" text-md md:text-lg text-white mb-10 w-full md:w-3/4">
        Signs are snippets of future ways of thinking and living that are
        affected by extreme climate changes:
      </div>

      <div className="sticky top-20 z-30 text-white">
        <div className="flex">
          <DropDownButton
            menuItems={GetCategories(signs)}
            signs={signs}
            setSignFilteredData={setSignFilteredData}
            setSelectedSignCategory={setSelectedSignCategory}
            menuTitle={"category"}
            selectedSignCategory={selectedSignCategory}
            selectedSignLocation={selectedSignLocation}
            selected={selected}
            setSelected={setSelected}
          />
          <DropDownButton
            menuItems={GetLocations(signs)}
            signs={signs}
            setSignFilteredData={setSignFilteredData}
            setSelectedSignLocation={setSelectedSignLocation}
            menuTitle={"locations"}
            selectedSignCategory={selectedSignCategory}
            selectedSignLocation={selectedSignLocation}
            selected={selected}
            setSelected={setSelected}
          />
        </div>
      </div>
      {/* All signs here */}
      <div ref={containerRef}>
        <SignsGridClean signFilteredData={signFilteredData} />
      </div>
    </Layout>
  );
};

export const query = graphql`
  query SignsQuery {
    allStrapiSign {
      nodes {
        slug
        title
        year
        locationContext {
          city
          country
        }
        categories {
          category
        }
        heroImage {
          localFile {
            childImageSharp {
              gatsbyImageData(
                placeholder: DOMINANT_COLOR
                formats: NO_CHANGE
                #width: 700
                height: 400
                quality: 100
                #layout: FULL_WIDTH
                transformOptions: { fit: COVER }
                layout: CONSTRAINED
              )
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
    allStrapiCategory {
      group(field: category) {
        edges {
          node {
            category
          }
        }
      }
    }
    allStrapiEvent {
      nodes {
        addReactions {
          participantImage {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          participantName
          participantQuote
        }
        eventSigns {
          categories {
            category
          }
        }
        eventImages {
          localFile {
            childImageSharp {
              gatsbyImageData(
                placeholder: DOMINANT_COLOR
                formats: NO_CHANGE
                #  width: AUTO
                #height: 300
                quality: 100
                #layout: FULL_WIDTH
                transformOptions: {
                  fit: COVER
                  cropFocus: ATTENTION
                  duotone: { highlight: "#fcd34d", shadow: "#000000" }
                }
                layout: CONSTRAINED
              )
            }
          }
        }
        eventLocation {
          country
        }
        eventDate
        eventFeatured
        eventTitle
        eventType
        slug
      }
    }
  }
`;

export default Page;

// *---> SEO
export const Head = () => (
  <Seo
    title={"All the signs"}
    description={
      " Specualtive design Signs Exploring The Impact Of Extreme Climate Futures Through Signage Systems"
    }
    keywords={"specualtive design, sign, sign design"}
  />
);
